import React, { useEffect, useState } from 'react';
import { useAccountContext } from "../../providers/AccountProvider";
import { useApiContext } from "../../providers/ApiProvider";
import { AutoComplete, Button, Dropdown, Input, Menu, Space, Spin, Tooltip } from "antd";
import { CheckCircleTwoTone, SyncOutlined, SearchOutlined, MoreOutlined, WarningTwoTone } from "@ant-design/icons";
import { useSEOContext } from "../../providers/SEOProvider";

const HapiConnect = () => {

  const [accountState, accountDispatch] = useAccountContext();
  const { account } = accountState;
  const { accountSet } = accountDispatch;
  const [apiDispatch] = useApiContext();
  const { apiFetchCollection, apiUpdateEntity, apiPostEntity } = apiDispatch;
  const [seoState, seoDispatch] = useSEOContext();
  const { reload, setter } = seoDispatch;

  const [websites, setWebsites] = useState([]);
  const [website, setWebsite] = useState(null);

  const [propositions, setPropositions] = useState([]);
  const [searchEtb, setSearchEtb] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!account?.hidHapi) {
      fetchWebsites();
      setWebsite(null)
    }
    else {
      fetchWebsite(account.hidHapi);
    }
  }, [account])

  function fetchWebsites() {
    apiFetchCollection("hapi/websites", {}, response => {
      setWebsites(response.result.filter(website => website.state.type === "DEV" || website.state.type === "PROD"));
    })
  }

  function fetchWebsite(hid) {
    if (website?.id == hid || website == hid)
      return
    apiFetchCollection(`hapi/website/${hid}`, {}, response => {
      if (response?.success === 1 && response?.total === 1)
        setWebsite(response.result[0])
      else
        setWebsite(hid)
    })
  }

  function removeAccent(string) {
    return string.normalize('NFD').replace(/\p{Diacritic}/gu, "").toLowerCase();
  }

  function search(q) {
    setPropositions([])
    clearTimeout(searchEtb)
    setSearchEtb(setTimeout(async () => {
      const prediction = websites.filter(website => removeAccent(website.enseigne).includes(removeAccent(q))).map(website => {
        return { value: `#${website.id} ${website.enseigne} (${website.state.type})`, key: website.id, data: website }
      }).slice(0, 10);
      setPropositions(prediction)
    }, 500))
  }

  function handleSelect(value, data) {
    setLoading(true);
    let params = { hidHapi: String(data.key) }

    if (data.data.real_domain)
      params.url = `https://${data.data.real_domain}`;

    apiUpdateEntity("establishments", account.id, params, response => {
      let _account = { ...account };
      _account.hidHapi = response.hidHapi;
      _account.url = response.url;
      accountSet(_account);
      setPropositions([]);
      setSearchEtb(null);
      setLoading(false);
    })
  }

  function injectPages() {
    setter('loading', true);
    setLoading(true);
    const params = { filters: [{ name: "lng", value: seoState.language }] }
    apiFetchCollection(`hapi/inject-pages/${account.hidHapi}/${account.id}`, params, response => {
      reload();
      setLoading(false);
    })
  }

  function handleClick({ key }) {
    //console.log(key)
    switch (key) {
      case 'resetAll':
        resetAll();
        break;
      case 'unlink':
        unlink();
        break;
    }
  }

  function resetAll() {
    setter('loading', true);
    const params = { etbId: account.id }

    apiPostEntity('clean-etb-seo', params, response => {
      reload();
    })
  }

  function unlink() {
    setLoading(true);
    const params = { hidHapi: null }

    apiUpdateEntity("establishments", account.id, params, response => {
      let _account = { ...account };
      _account.hidHapi = response.hidHapi;
      accountSet(_account);
      setLoading(false);
    })
  }

  const menu = (
    <Menu onClick={handleClick}>
      <Menu.Item key="resetAll">
        <Space>
          <WarningTwoTone twoToneColor="red" />
          <span style={{ color: 'red' }}>Reset All</span>
          <WarningTwoTone twoToneColor="red" />
        </Space>
      </Menu.Item>
      <Menu.Item key="unlink">
        <Space>
          <WarningTwoTone twoToneColor="red" />
          <span style={{ color: 'red' }}>Unlink HAPI</span>
          <WarningTwoTone twoToneColor="red" />
        </Space>
      </Menu.Item>
    </Menu>
  )

  let hapi_state = typeof website === "object" ? website : null;

  return (
    <div>
      {hapi_state ?
        <Space>
          <CheckCircleTwoTone twoToneColor="#52c41a" />
          <em style={{ color: hapi_state?.state?.type === "PROD" ? 'green' : 'tomato' }}>Lié a HAPI : {hapi_state?.enseigne || 'unknown'} ({hapi_state?.id || 'unknown'}) {hapi_state?.state?.type || 'unknown'} {hapi_state?.state?.online ? "🟢" : "🔴"}</em>
          <Tooltip title="Synchroniser les données depuis HAPI. Attention certaine données pourrons être écrasé."
            placement="left">
            <Button
              size="small"
              icon={<SyncOutlined />}
              loading={loading}
              onClick={injectPages}
              shape="circle"
              type="primary"
            />
          </Tooltip>

          <Dropdown overlay={menu} trigger={['click']}>
            <MoreOutlined />
          </Dropdown>
        </Space>
        :
        <Spin spinning={loading}>
          <AutoComplete
            onSelect={handleSelect}
            onSearch={search}
            options={propositions}
            style={{ width: 400 }}
          >
            <Input
              placeholder="Lier a Hapi"
              suffix={<SearchOutlined />}
            />
          </AutoComplete>
        </Spin>}
    </div>
  );
};

export default HapiConnect;
