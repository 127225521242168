import React, { useEffect, useState } from "react"
import { Button, Dropdown, Input, Menu, message, Space, Tooltip } from "antd"
import { copy } from "../../Helpers";
import { CopyOutlined } from '@ant-design/icons';
import CheckRedirections from "./CheckRedirections";

export default function StepThree({ result, setCurrent, finalResult, setFinalResult, apiUpdateEntity, redirId }) {

  const [domain, setDomain] = useState("")
  const [isDevDomain, setIsDevDomain] = useState(false);

  useEffect(() => {
    if (!finalResult) {
      let txt = result.map(r => "\"" + r.old.replace('https:', 'http:') + "\" \"" + r.new.replace('http:', 'https:') + "\"")
      handleSort(txt)
    }
  }, [])

  function handleSort(txt) {
    console.time('count')
    let { normalLinks, smallEnpointArray, baseRedir } = extractSmallEndpoint(txt)
    normalLinks = sortLinksArray(normalLinks)
    smallEnpointArray = sortLinksArray(smallEnpointArray)
    let result = [...normalLinks, ...smallEnpointArray, ...baseRedir];

    const regex = /^"(https?:\/\/)?(www\.)?/
    setFinalResult(result.map(row => row.replace(regex, '"')).join('\n'))

    console.timeEnd('count')
  }

  function extractSmallEndpoint(array) {
    let normalLinks = [];
    let smallEnpointArray = [];
    let baseRedir = []
    let regex = /"https?:\/\/\S*\/[.{2,3}\/]?"/
    array.map(line => {
      if (line.length === 0) {
        return
      }
      let linkArray = line.split(' ')
      if (regex.test(linkArray[1])) {
        smallEnpointArray.push(line)
      } else if (linkArray[0] === '"/"') {
        baseRedir.push(line)
      } else {
        normalLinks.push(line)
      }
    })
    return { normalLinks, smallEnpointArray, baseRedir }
  }

  function sortLinksArray(array) {
    array.sort((a, b) => {
      a = a.split(' ')[0].replace('-', '_');
      b = b.split(' ')[0].replace('-', '_')
      if (a > b) {
        return -1;
      }
      if (a > b) {
        return -1;
      }
      if (b > a) {
        return 1;
      }
      return 0;
    })
    return array;
  }

  function save() {
    const data = {
      result: finalResult
    }
    apiUpdateEntity("redirections", redirId, data, response => {
      message.success("Résultat enregistré")
    })
  }

  function replaceDomain() {
    const regex = /^(www.)?[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/
    if (!regex.test(domain)) {
      return message.error("format url non valide. Ex: wwww.nouveau-ndd.com ou nouveau-ndd.com")
    }

    const rows = finalResult.split("\n")
    var array = rows.map(r => {
      const arr = r.split("\" \"")
      let oldDomain = arr[1].split("//")[1].split("/")[0]
      return arr[0] + "\" \"" + arr[1].replace(oldDomain, domain)
    })

    setFinalResult(array.join('\n'))
  }

  function copyWithMessage() {
    const success = copy(finalResult)

    return success ? message.success("Texte copié") : message.error("un problème est survenue")
  }

  function simplify() {
    const regex = /^"(https?:\/\/)?(www\.)?/
    setFinalResult(finalResult.split('\n').map(row => row.replace(regex, '"')).join('\n'))

    // let rows = finalResult.split('\n');
    //
    // const results = rows.map(row => {
    //   let [old, future] = row.split('\" \"');
    //   old = old.replace(/^"https?:\/\/.*\.(com|fr|org|paris|eu)/, '\"') + '\"';
    //   future = '\"' + future.replace(/^https?:\/\/.*\.(com|fr|org|paris|eu)/, '');
    //
    //   return old + ' ' + future;
    // })
    //
    // setFinalResult(results.join('\n'));
  }

  function lined() {
    const array = finalResult.split('\n');
    return array.map((row, index) => {
      return `${String(index + 1).padStart(3, '0')}    ${row}`;
    }).join('\n')
  }

  useEffect(() => {
    setIsDevDomain(checkDevDomain())
  }, [finalResult])

  function checkDevDomain() {
    let isDev = false;
    finalResult.split('\n').map(row => {
      if (row.includes(`.${process.env.HAPI_CMS_DOMAIN}`))
        isDev = true;
    })

    return isDev;
  }

  function handleScroll() {
    let highlightsDiv = document.getElementById('disabledTextarea');
    let textArea = document.getElementById('goodTextarea')

    if (textArea.scrollHeight - textArea.scrollTop === textArea.clientHeight) {
      textArea.value = textArea.value.trim()
    }
    var scrollTop = textArea.scrollTop;
    highlightsDiv.scrollTop = scrollTop;

    var scrollLeft = textArea.scrollLeft;
    highlightsDiv.scrollLeft = scrollLeft;
  }

  function handleTextChange() {
    let highlightsDiv = document.getElementById('disabledTextarea');
    let textArea = document.getElementById('goodTextarea')

    highlightsDiv.innerHTML = textArea.value
    highlightsDiv.scrollHeight = textArea.scrollHeight
    // console.log(textArea.scrollHeight)
    // console.log(highlightsDiv.scrollHeight)
  }

  function copyWithoutDomain() {
    const regex = /"(https?:\/\/)?(www\.)?\S+?\..{2,3}\//gm
    const success = copy(finalResult.replace(regex, '"/'))

    return success ? message.success("Texte copié") : message.error("un problème est survenue")

  }


  return (
    <div>
      <div className="margin-10 flex space-between">
        <Button type="primary" onClick={() => setCurrent(1)}>Etape précédente</Button>
        <div>
          <Input
            placeholder="Remplacer nom de domaine"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
            style={{ width: 250 }}
            allowClear
          />&nbsp;
          <Button onClick={replaceDomain}>Remplacer</Button>
        </div>
        <Space>
          <Button onClick={() => handleSort(finalResult.split('\n'))}>sort</Button>
          <Button onClick={simplify}>Simplify</Button>
          <Button type="primary" onClick={save}>Enregister</Button>
        </Space>
      </div>
      {isDevDomain ?
        <div style={{ color: 'red' }}>Attention le nom de domaine du nouveau site est celui d'un site en développement, veuillez le remplacer.</div> : null}
      <div style={{ position: 'relative', minHeight: 620 }}>
        <Input.TextArea
          id="disabledTextarea"
          wrap="off"
          disabled
          style={{
            position: 'absolute',
            paddingLeft: 18,
            paddingTop: 10,
            backgroundColor: 'transparent'
          }}
          autoSize={{ minRows: 25, maxRows: 25 }}
          value={lined()}
          onChange={(e) => setFinalResult(e.target.value)}
        />

        <Input.TextArea
          id="goodTextarea"
          wrap={"off"}
          style={{
            position: 'absolute',
            paddingLeft: 55,
            paddingTop: 10,
            backgroundColor: 'transparent'
          }}
          autoSize={{ minRows: 25, maxRows: 25 }}
          value={finalResult}
          onChange={(e) => {
            setFinalResult(e.target.value)
            handleTextChange()
          }}
          onScroll={handleScroll}
        />
      </div>

      <div className="mt-20">
        <Tooltip title="Copier toutes les redirections en un click !">
          <Button type="primary" onClick={copyWithMessage}>Copier les redirections</Button>
        </Tooltip>
        <Tooltip title="Copier toutes les redirections sans les domaines en un click !">
          <Button type="primary" style={{ marginLeft: 5 }} onClick={copyWithoutDomain}>Copier sans les domaines</Button>
        </Tooltip>

      </div>

      <CheckRedirections finalResult={finalResult} />
    </div>
  )
}
